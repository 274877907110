
<template>
    <b-container fluid>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                      <h4 class="card-title">{{ $t('globalTrans.attachment') }} {{ $t('farm_config.list') }}</h4>
                    </template>
                    <!-- <template v-slot:headerAction>
                      <b-button variant="primary btn_add_new" v-b-modal.modal-4 @click="resetId">
                        <i class="fas fa-plus"></i>{{ $t('globalTrans.add_new') }}
                      </b-button>
                    </template> -->
                    <template v-slot:body>
                      <b-overlay :show="loadingState">
                        <b-row>
                            <b-col md="12" class="table-responsive">
                                <b-table  :emptyText="$t('globalTrans.noDataFound')" show-empty thead-class="table_head" striped bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                                    <template v-slot:cell(index)="data">
                                        {{ $n(data.index + pagination.slOffset) }}
                                    </template>
                                    <template v-slot:cell(status)="data">
                                        <span class="badge badge-success" v-if="data.item.status == 1">{{$t('globalTrans.active')}}</span>
                                        <span class="badge badge-danger" v-else>{{$t('globalTrans.inactive')}}</span>
                                    </template>
                                    <template v-slot:cell(attachment)="data">
                                        <template v-if=" data.item.attachment">
                                            <a target="_blank" :href="incentiveGrantServiceBaseUrl + '/' + data.item.attachment" title="Attachment" class="mr-3">
                                                <!-- <i class="ri-download-cloud-fill"></i> -->
                                                {{$t('globalTrans.view')}}
                                            </a>
                                        </template>
                                    </template>
                                    <template v-slot:cell(attachment_name_id)="data">
                                      {{ getAttachmentName(data.item.attachment_name_id) }}
                                  </template>
                                  <template v-slot:cell(action)="data">
                                      <a href="javascript:" class="btn_table_action table_action_edit" title="Edit" v-b-modal.modal-4 size="sm" @click="edit(data.item)">
                                          <i class="ri-ball-pen-fill"></i>
                                      </a>
                                  </template>
                                </b-table>
                            </b-col>
                        </b-row>
                      </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-4" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Form :id="editItemId" :key="editItemId"/>
        </b-modal>
    </b-container>
</template>
<script>
import Form from './Form'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { attachmentList } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    Form
  },
  data () {
    return {
        incentiveGrantServiceBaseUrl: incentiveGrantServiceBaseUrl,
        attachmentNameList: [
            { value: 1, text: this.$t('farm_config.machine_type') },
            { value: 2, text: this.$t('cotton_ginner_grower.application_instruction') }
        ]
    }
  },
  mounted () {
  },
  computed: {
    formTitle () {
      return (this.editItemId === 0) ? this.$t('globalTrans.attachment') + ' ' + this.$t('globalTrans.add') : this.$t('globalTrans.attachment') + ' ' + this.$t('globalTrans.modify')
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
          { label: this.$t('globalTrans.name'), class: 'text-center' },
          { label: this.$t('globalTrans.attachment'), class: 'text-center' },
          // { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'attachment_name_id' },
          { key: 'attachment' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'attachment_name_id' },
          { key: 'attachment' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
    }
  },
  watch: {
    loadingState: function (newVal, oldVal) {
      if (newVal) {
        this.loadData()
      }
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    edit (item) {
      this.editItemId = item.id
    },
    loadData () {
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(incentiveGrantServiceBaseUrl, attachmentList).then(response => {
        if (response.success) {
          this.$store.dispatch('setList', response.data)
        } else {
          this.$store.dispatch('setList', [])
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    getAttachmentName (id) {
      const obj = this.attachmentNameList.find(item => item.value === id)
      return this.currentLocale === 'en' ? obj?.text : obj?.text
    }
  }
}
</script>
