<template>
    <b-container fluid>
        <b-row>
            <b-col lg="12" sm="12">
                <iq-card>
                    <template v-slot:body>
                        <b-overlay :show="loading">
                            <b-row>
                                <b-col lg="12" sm="12">
                                    <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                                      <b-form @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset" >
                                          <b-row>
                                            <b-col lg="12" sm="12">
                                              <ValidationProvider name="Gender" vid="attachment_name_id" rules="required|min_value:1">
                                                <b-form-group class="row" label-cols-sm="4" label-for="attachment_name_id" slot-scope="{ valid, errors }">
                                                  <template v-slot:label>
                                                    {{ $t('agri_portal.attachment_name') }} <span class="text-danger">*</span>
                                                  </template>
                                                    <b-form-select
                                                        plain
                                                        v-model="machineryFarmer.attachment_name_id"
                                                        :options="attachmentNameList"
                                                        id="attachment_name_id"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        disabled
                                                        >
                                                        <template v-slot:first>
                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                    </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                                </b-form-group>
                                              </ValidationProvider>
                                            </b-col>
                                            <b-col lg="12" sm="12">
                                              <ValidationProvider name="Attachment" vid="attachment" rules="ext:pdf">
                                                <b-form-group class="row" label-cols-sm="4" label-for="attachment" slot-scope="{ valid, errors }">
                                                  <template v-slot:label>
                                                    {{ $t('farm_config.attachment') }} <span class="text-danger">*</span>
                                                    <v-slot v-if="machineryFarmer.attachment !== null">
                                                        <a target="_blank" :href="incentiveGrantServiceBaseUrl + '/' + machineryFarmer.attachment" title="Attachment" class="mr-3"><i class="ri-download-cloud-fill"></i></a>
                                                    </v-slot>
                                                  </template>
                                                  <b-form-file
                                                    id="attachment"
                                                    v-model="machineryFarmer.attachment"
                                                    :placeholder="$t('common_config.file_chosen')"
                                                    v-on:change="handleFile"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                  </b-form-file>
                                                  <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                  </div>
                                                </b-form-group>
                                              </ValidationProvider>
                                            </b-col>
                                          </b-row>
                                          <div class="row">
                                              <div class="col-sm-3"></div>
                                              <div class="col text-right">
                                                  <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                                  &nbsp;
                                                  <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                                              </div>
                                          </div>
                                      </b-form>
                                    </ValidationObserver>
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { attachmentStore, attachmentUpdate } from '../../api/routes'

export default {
    props: ['id'],
    components: {
        ValidationObserver,
        ValidationProvider
    },
    created () {
        if (this.id) {
            const tmp = this.getmachineryFarmerData()
            this.machineryFarmer = tmp
        }
    },
    data () {
        return {
            incentiveGrantServiceBaseUrl: incentiveGrantServiceBaseUrl,
            loading: false,
            saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
            machineryFarmer: {
                attachment_name_id: 0,
                edit_attachment: '',
                attachment: []
            },
            attachmentFile: [],
            attachmentNameList: [
              { value: 1, text: this.$t('farm_config.machine_type') },
              { value: 2, text: this.$t('cotton_ginner_grower.application_instruction') }
            ]
        }
    },
    mounted () {
      core.index()
    },
    methods: {
      getmachineryFarmerData () {
        const tmpData = this.$store.state.list.find(item => item.id === this.id)
        return JSON.parse(JSON.stringify(tmpData))
      },
      onFileChange (e) {
        this.attachmentFile = e.target.files[0]
      },
      handleFile (e) {
        const selectedPhoto = e.target.files[0]
        const reader = new FileReader()
        reader.onload = (event) => {
            if (this.id) {
            this.machineryFarmer.edit_attachment = event.target.result
            } else {
            this.machineryFarmer.attachment = event.target.result
            }
        }
        reader.readAsDataURL(selectedPhoto)
        },
      async saveData () {
          this.loading = true
          this.$store.dispatch('mutateCommonProperties', { loading: true })
          let result = null
          const loadingState = { loading: false, listReload: false }

        if (this.id) {
            result = await RestApi.putData(incentiveGrantServiceBaseUrl, `${attachmentUpdate}/${this.id}`, this.machineryFarmer)
        } else {
            result = await RestApi.postData(incentiveGrantServiceBaseUrl, attachmentStore, this.machineryFarmer)
        }

          loadingState.listReload = true
          this.$store.dispatch('mutateCommonProperties', loadingState)
          this.loading = false
          if (result.success) {
            this.$store.commit('incentiveGrant/mutateIncentiveGrantServiceProperties', { hasDropdownLoaded: false })
            this.$toast.success({
              title: this.$t('globalTrans.success'),
              message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
              color: '#D6E09B'
            })
            this.$bvModal.hide('modal-4')
          } else {
            this.$toast.error({
              title: 'Success',
              message: result.message,
              color: '#D6E09B'
            })
            this.$refs.form.setErrors(result.errors)
          }
      }
    }
}
</script>
